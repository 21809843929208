import './App.css'

import { observer } from 'mobx-react-lite'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'

import { MainPage } from './page/MainPage'
import { LocalStorage } from './store/LocalStorage'
import { PreparePage } from './page/PreparePage'
import { TosPage } from './page/TosPage'
import { NotFoundPage } from "./page/NotFoundPage";
import { ToastProvider } from './context/ToastContext';


function App() {
  return (
    <ToastProvider>
      <div className={`App ${LocalStorage.tosAccepted && LocalStorage.preAccepted && (LocalStorage.roomState === 'connecting' && LocalStorage.name && LocalStorage.name !== '') ? 'connecting' : ''}`}>
        <Router>
          <Route path='/' exact={false}>
            {LocalStorage.tosAccepted ? (LocalStorage.preAccepted ? (LocalStorage.roomState === 'notexist' ? <NotFoundPage /> : <MainPage />) : <PreparePage />) : <TosPage />}
          </Route>
        </Router>
      </div>
    </ToastProvider>
  );
}

export default observer(App)
