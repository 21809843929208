import './LanguageFlag.css'
import type { ILanguages } from '../util/language'

import { useCallback, useEffect, useState } from 'react';
// import { type DetailedHTMLProps, type HTMLAttributes, useCallback } from 'react'

import { ILanguageObject } from '../util/language'
import AutomaticFlag from '../assets/flag_all.png'
import { useTranslation } from 'react-i18next'
import { LocalStorage } from '../store/LocalStorage';


type LanguageFlagProps = JSX.IntrinsicElements['div'] & {
  language?: ILanguageObject | null,
  onItemClick?: (id: ILanguages | null) => void,
  active?:   boolean,
  showName?: boolean,
}

export const LanguageFlag = (props: LanguageFlagProps) => {
  const {t} = useTranslation()
  const {
    className,
    language,
    onClick,
    onItemClick,
    active = false,
    showName = false,
    ...divProps } = props
  const [disabled, setDisabled] = useState<boolean>(false);

  const languageID = language?.id ?? null

  const handleClick = useCallback((e: any) => {
    onClick?.(e)
    onItemClick?.(languageID)
  }, [languageID, onClick, onItemClick])

  const image = language?.img ?? AutomaticFlag
  const alt   = language?.languageName ?? "Unspecified"
  const name  = language?.languageName ?? t("言語指定なし")

  const changeDisabled = useCallback(() => {
    if (languageID === null) {
      setDisabled(false);
      return;
    }
    if (LocalStorage.language.includes(languageID)) {
      setDisabled(false);
      return;
    }

    if (LocalStorage.translationLanguage === languageID) {
      setDisabled(false);
      return;
    }

    const code = languageID.slice(0, 2);
    const index = LocalStorage.language.findIndex(l => l.slice(0, 2) === code);
    if (index !== -1) {
      setDisabled(true);
      return;
    }

    setDisabled(LocalStorage.translationLanguage.slice(0, 2) === code);

  }, [languageID]);

  useEffect(() => {
    changeDisabled();
    // ローカルストレージのデータが変更された際に発火するリスナーとして追加
    window.addEventListener('localstorage-change-language', changeDisabled);
    window.addEventListener('localstorage-change-translationLanguage', changeDisabled);

    return () => {
      // リスナー解除
      window.removeEventListener('localstorage-change-language', changeDisabled);
      window.removeEventListener('localstorage-change-translationLanguage', changeDisabled);
    };
  }, [changeDisabled]);

  return (
    <div
      {...divProps}
      className={`language-flag ${disabled ? ' disabled' : ''} ${active ? ' active' : ''} ${className || ''}`}
      onClick={handleClick}
    >
      <img src={image} alt={alt} />
      {!!showName && (
        <span>{name}</span>
      )}
      {!!active && (
        <i className='active-icon bi bi-check-circle-fill' />
      )}
    </div>
  )
}
