import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export type InterimToastHandles = {
  showInterim: (message: string) => void;
  dismissInterim: () => void;
  setIsEnabled: (isEnabled: boolean) => void,
}

export type InterimToastProps = {
  autoHide?: boolean,
  delay?: number,
}

const InterimToast = forwardRef<InterimToastHandles, InterimToastProps>(({autoHide = true, delay = 5000}: InterimToastProps, ref) => {
  const [text, setText] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>(false);
  const [isEnabled, setIsEnabled] = useState<boolean>(true);

  const idRef = useRef<number | NodeJS.Timeout>();
  const isBlockedRef = useRef<boolean>(isBlocked);

  useEffect(() => {
    isBlockedRef.current = isBlocked;
  }, [isBlocked]);

  const dismissInterim = useCallback(() => {
    setText('');
    setShow(false);
    setIsBlocked(false);
  }, []);

  const setTimer = useCallback(() => {
    if (idRef.current) {
      clearTimeout(idRef.current);
    }
    idRef.current = setTimeout(() => {
      !isBlockedRef.current && dismissInterim();
    }, delay);
  }, [delay, dismissInterim]);

  const showInterim = useCallback((message: string) => {
    if (isBlocked) return;
    setText(message);
    setShow(true);
    if (autoHide) {
      setTimer();
    }
  }, [autoHide, isBlocked, setTimer]);

  useEffect(() => {
    if (show) {
      setTimer();
    }
  }, [setTimer, show]);

  useEffect(() => {
    return () => {
      if (idRef.current) clearTimeout(idRef.current);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    showInterim: showInterim,
    dismissInterim: dismissInterim,
    setIsEnabled: (isEnabled) => setIsEnabled(isEnabled),
  }));

  return (
    <>
      {isEnabled && (
          <ToastContainer
          aria-live="assertive"
          aria-atomic="true"
          style={{
            position: 'fixed',
            zIndex: 10000,
            bottom: '70px',
            width: '100%',
          }}
        >
          <Toast
            show={show}
            onClick={() => {setShow(false); setIsBlocked(true); }}
            style={{
              display: 'block',
              margin: '0 auto',
              padding: '0 4px',
              textAlign: 'center',
              width: '100dvw',
              maxWidth: '650px',
              minHeight: '2.2em',
              boxSizing: 'border-box',
              backgroundColor: 'rgb(255, 255, 255)',
              borderRadius: '8px',
              cursor: 'pointer',
            }}>
            <Toast.Body
              style={{
                fontSize: '30px',
                letterSpacing: '1.4px',
                color: '#000',
              }}>{text}</Toast.Body>
          </Toast>
        </ToastContainer>
      )}
    </>
  );
});

export default InterimToast;
