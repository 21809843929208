import { useCallback, useEffect, useRef, useState } from 'react';

// ノイズアラートの型定義
export type NoiseAlert = {
  timestamp: number;         // アラート発生時のタイムスタンプ
  noiseLevel: number;        // 検出されたノイズレベル
  duration: number;          // ノイズの持続時間
  type: string[];            // 環境ノイズの種類
  averageNoiseLevel: number; // 平均ノイズレベル
};

// 音声分析結果の型定義
export type AnalysisResult = {
  timestamp: number;            // 分析時のタイムスタンプ
  noiseLevel: number;           // 現在のノイズレベル
  voiceActivity: boolean;       // 音声活動の検出フラグ
  voiceScore: number;           // 音声らしさのスコア
  environmentalNoise: string[]; // 検出された環境ノイズの種類
  sustainedNoise: {             // 持続的なノイズの情報
    isNoisy: boolean;           // ノイズ状態かどうか
    duration: number;           // ノイズの持続時間
    averageNoiseLevel: number;  // 平均ノイズレベル
  };
  alert: boolean;               // アラート発生フラグ
};

// フックのプロパティ型定義
export type NoiseMonitorProps = {
  // ノイズアラート発生時のコールバック
  onNoiseAlert?: (alert: NoiseAlert) => void;
  // ノイズ検出の閾値設定
  thresholds?: {
    noiseLevel: number;    // ノイズレベルの閾値
    alertDuration: number; // アラート発生までの持続時間
  };
};

// モニタリング状態の型定義
export type MonitoringState = {
  isMonitoring: boolean;               // モニタリング実行中フラグ
  currentNoiseLevel: number;           // 現在のノイズレベル
  currentNoiseDuration: number;        // 現在のノイズ持続時間
  noiseAlerts: NoiseAlert[];           // 検出されたアラートの履歴
  lastAnalysis: AnalysisResult | null; // 最新の分析結果
};

// ノイズモニタリングカスタムフック
export const useNoiseMonitor = ({onNoiseAlert = () => {}, thresholds = {noiseLevel: 60, alertDuration: 10000}}: NoiseMonitorProps = {}) => {
  // モニタリング状態の初期化
  const [monitoringState, setMonitoringState] = useState<MonitoringState>({
    isMonitoring: false,
    currentNoiseLevel: 0,
    currentNoiseDuration: 0,
    noiseAlerts: [],
    lastAnalysis: null,
  });
  // Web Workerの参照を保持
  const workerRef = useRef<Worker | null>(null);
  const sampleRateRef = useRef<number | null>(null);

  // コールバックと閾値の参照を最新に保つ
  const onNoiseAlertRef = useRef(onNoiseAlert);
  useEffect(() => {
    onNoiseAlertRef.current = onNoiseAlert;
  }, [onNoiseAlert]);
  const thresholdsRef = useRef(thresholds);
  useEffect(() => {
    thresholdsRef.current = thresholds;
  }, [thresholds]);

  // 文字起こし中かどうか
  const [isTranscribing, setIsTranscribing] = useState<boolean>(false);
  const isTranscribingRef = useRef<boolean>(isTranscribing);
  useEffect(() => {
    isTranscribingRef.current = isTranscribing;
  }, [isTranscribing]);

  // Workerからのメッセージを処理するハンドラー
  const handleWorkerMessage = useCallback((event: MessageEvent<{
    type: string;
    data: AnalysisResult | { message: string };
  }>) => {
    const { type, data } = event.data;
    //console.log('Worker message received:', { type, data });

    switch (type) {
      case 'analysisResult':
        if ('noiseLevel' in data) {
          // console.debug('Analysis result:', {
          //   alert: data.alert,
          //   noiseLevel: data.noiseLevel,
          //   noiseDuration: data.sustainedNoise.duration,
          //   isVoice: data.voiceActivity,
          // });
          // 状態の更新
          setMonitoringState(prev => ({
            ...prev,
            currentNoiseLevel: data.noiseLevel,
            currentNoiseDuration: data.sustainedNoise.duration,
            lastAnalysis: data,
          }));

          // アラート条件を満たした場合の処理
          if (data.alert) {
            const alertData: NoiseAlert = {
              timestamp: data.timestamp,
              noiseLevel: data.noiseLevel,
              duration: data.sustainedNoise.duration,
              type: data.environmentalNoise,
              averageNoiseLevel: data.sustainedNoise.averageNoiseLevel,
            };

            //console.log('Creating alert:', alertData);

            // アラート履歴の更新とコールバックの実行
            setMonitoringState(prev => ({
              ...prev,
              noiseAlerts: [...prev.noiseAlerts, alertData],
            }));

            onNoiseAlertRef.current(alertData);
          }
        }
        break;

      case 'analysisError':
        // エラー処理
        if ('message' in data) {
          console.error('Analysis error:', data.message);
        }
        break;

      case 'settingsUpdated':
        // 設定更新の確認
        console.log('Settings updated:', data);
        break;

      case 'resetComplete':
        // 分析履歴のリセット処理
        //console.log('Analysis history reset');
        setMonitoringState(prev => ({
          ...prev,
          noiseAlerts: [],
          lastAnalysis: null,
        }));
        break;
    }
  }, []);

  // Workerの初期化処理
  const initializeWorker = useCallback((sampleRate: number) => {
    if (!workerRef.current) {
      // Workerの作成と設定
      workerRef.current = new Worker('/workers/audio-analyzer.worker.js');
      workerRef.current.onmessage = handleWorkerMessage;
      sampleRateRef.current = sampleRate;

      // 初期設定の送信
      workerRef.current.postMessage({
        type: 'updateSettings',
        data: {
          sampleRate: sampleRateRef.current,
          thresholds: {
            noiseLevel: thresholdsRef.current.noiseLevel,
            alertDuration: thresholdsRef.current.alertDuration
          },
          isTranscribing: isTranscribingRef.current,
        }
      });
    }
  }, [handleWorkerMessage]);

  // Workerの終了処理
  const terminateWorker = useCallback(() => {
    workerRef.current?.terminate();
    workerRef.current = null;
  }, []);

  // 音声データの分析要求
  const analyzeAudio = useCallback((pcmData: ArrayBuffer) => {
    if (!workerRef.current) return;

    workerRef.current.postMessage({
      type: 'analyzeAudio',
      data: { pcmData },
    });
  }, []);

  useEffect(() => {
    workerRef.current && sampleRateRef.current && workerRef.current.postMessage({
      type: 'updateSettings',
      data: {
        sampleRate: sampleRateRef.current,
        thresholds: {
          noiseLevel: thresholdsRef.current.noiseLevel,
          alertDuration: thresholdsRef.current.alertDuration
        },
        isTranscribing: isTranscribing,
      }
    });
  }, [isTranscribing]);

  // クリーンアップ処理
  useEffect(() => {
    return () => {
      terminateWorker();
    };
  }, [terminateWorker]);

  // フックの戻り値
  return {
    ...monitoringState,
    analyzeAudio,
    initializeWorker,
    terminateWorker,
    setIsTranscribing,
  };
};
