import './RecordButton.css'

import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from 'react';
import { observer } from 'mobx-react-lite'
import { useTranslation } from "react-i18next";
import { useToast } from '../context/ToastContext';
import { toast as hotToast }  from 'react-hot-toast';
import { detect, BotInfo, BrowserInfo, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo } from 'detect-browser';
import { ILanguages } from '../util/language';
import { LocalStorage } from '../store/LocalStorage'
import { useWavRecord } from '../hook/useWavRecord';
import { RecordButtonHandles, RecordButtonProps } from '../types/speech';

const SecondCounter = observer(() => {
  const state = LocalStorage.roomState
  const [dynTime, setDynTime] = useState(0)

  useEffect(() => {
    let handle: NodeJS.Timer | null = null
    const time = LocalStorage.endTime

    if (state === 'incall' && time) {
      handle = setInterval(() => {
        setDynTime(Math.floor((time.getTime() - Date.now()) / 1000))
      }, 1000)
    }

    return () => {
      if (handle)
        clearInterval(handle)
    }
  }, [state])

  if (state === 'waiting' || state === 'completed' || LocalStorage.endTime == null) {
    return null
  }

  const MINUTES = 60
  const HOURS   = 60 * MINUTES

  let time = Math.abs(dynTime)
  const hours = Math.floor(time / HOURS).toString().padStart(2, '0')
  time %= HOURS
  const minutes = Math.floor(time / MINUTES).toString().padStart(2, '0')
  time %= MINUTES
  const seconds = time.toString().padStart(2, '0')

  return <span className='gap-2'>
    <i className="bi bi-clock" />
     <span className={dynTime < 0 ? 'text-danger' : ''}>
       {dynTime < 0 ? ' -' : ' '}{hours}:{minutes}:{seconds}
     </span>
  </span>
})

const RecordButton = forwardRef<RecordButtonHandles, RecordButtonProps>(({ meetingId, onData, onRecordStateChange, changeLabels, roomOwnerId }: RecordButtonProps, ref) => {
  const { t } = useTranslation()
  const { showInterim, dismissInterim } = useToast();
  const browser = useMemo<BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | null>(() => detect(), []);

  // マイクボタンなどを無効化するフラグ
  const [isDisabled, setIsDisabled] = useState(false)

  // マイクデバイス一覧
  const [audioInputDevices, setAudioInputDevices] = useState<MediaDeviceInfo[]>([]);
  // 選択中のマイクデバイス
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState<string|null>(null);

  // STTに送信する話者の数
  const [clusterAmount, setClusterAmount] = useState(1)
  const clusterAmountRef = useRef(clusterAmount)
  useEffect(() => {
    clusterAmountRef.current = clusterAmount;
  }, [clusterAmount]);
  const updateClusterAmount = (newClusterAmount: number) => {
    console.log(`update cluster amount: ${clusterAmount} -> ${newClusterAmount}`);
    setClusterAmount(newClusterAmount);
  }

  // 話者分離機能未実装のSTTの場合は固定値を使用する
  const defaultTalkIdNumberRef = useRef<number>(1);
  const defaultSpeakerIdRef = useRef<string>("user1");

  // 文字起こしメッセージ受信後の処理
  const onMessage = useCallback((message: string, isFinal?: boolean, language?: string, talkId?: string, speakerId?: string) => {
    if (isFinal) {
      dismissInterim()
    } else {
      showInterim(message);
    }

    // 話者分離機能未実装のSTTの場合は固定値を使用する
    if (!talkId) {
      // 会話IDの数字部分を加算
      defaultTalkIdNumberRef.current++;
      // ゼロ埋めして会話IDを作成（既存機能に合わせる）
      talkId = "t" + String(defaultTalkIdNumberRef.current).padStart(5, '0');
    }
    if (!speakerId) {
      speakerId = defaultSpeakerIdRef.current;
    }
    // 既存機能に合わせて後続処理を実施
    onData && onData(talkId, speakerId, message, language || ILanguages["ja-JP"], !!isFinal)
  }, [dismissInterim, showInterim, onData])

  // マイクボタンなどを無効化する
  const onSetDisabled = useCallback((disabled: boolean) => {
    setIsDisabled(disabled);
  }, []);

  const {
    isConnected, // ソケット通信が開始されたか
    isAnalyzed, // 音声デバイスと接続・解析したか
    isStarted, // 文字起こしを開始したか
    isMonitoring, // 音声をキャプチャ中か
    isErrored, // エラーが発生したか
    build: recorderBuild, // レコーダー起動処理
    start: recorderStart, // 録音開始処理
    stop: recorderStop, // 録音停止処理
    monitoringStart, // 音声キャプチャ開始処理
    monitoringStop, // 音声キャプチャ終了（保存）処理
    dataURL, // 音声キャプチャデータ（base64URL）
    deviceId: recorderDeviceId, // レコーダーに設定されているデバイスID
    errorMessage, // エラー発生時のメッセージ
    errorReport, // エラー発生時の詳細
    currentNoiseLevel, // ノイズレベル
    currentNoiseDuration, // ノイズ持続時間
  } = useWavRecord({roomOwnerId, meetingId, clusterAmount: clusterAmountRef, onSetDisabled, onMessage})

  // レコーダーが起動したかのRef変数
  const isInitializedRef = useRef<boolean>(isAnalyzed);
  useEffect(() => {
    isInitializedRef.current = isAnalyzed;
  }, [isAnalyzed]);

  // 文字起こしを開始しているかのRef変数
  const isStartedRef = useRef<boolean>(isStarted);
  useEffect(() => {
    isStartedRef.current = isStarted;
  }, [isStarted]);

  // 文字起こし開始指示
  const start = useCallback((disabled?: boolean) => {
    if(isInitializedRef.current && !isStartedRef.current){
      recorderStart();
    }
  }, [recorderStart])

  // 文字起こし終了指示
  const stop = useCallback((disabled?: boolean) => {
    // ドラフト文表示停止
    dismissInterim();
    if(isInitializedRef.current && isStartedRef.current){
      recorderStop(!!disabled);
    }
  }, [dismissInterim, recorderStop]);

  // 音声レコーダー状態切替
  const handleToggle = useCallback(() => {
    if(!isStartedRef.current){
      start();
    }else{
      stop();
    }
  }, [start, stop]);

  // エラーメッセージ表示用のトースト
  useEffect(() => {
    // エラー状態の場合のみ処理
    if(!isErrored){
      return;
    }
    let message = "エラーが発生しました";
    if(errorMessage.current !== ''){
      message = errorMessage.current;
    }
    if(process.env.NODE_ENV !== 'production' && errorMessage.current !== ''){
      message += errorReport.current;
    }
    hotToast.error(message, {position: 'top-right'});
  }, [errorMessage, errorReport, isErrored]);

  // 音声監視状態切替
  const handleWatchToggle = useCallback(() => {
    if (isMonitoring) {
      // すでに監視中の場合は停止（保存）
      monitoringStop();
    } else {
      // 監視スタート
      monitoringStart();
    }
  }, [isMonitoring, monitoringStart, monitoringStop]);

  // マイクデバイス一覧（Ref）
  const audioInputDevicesRef = useRef(audioInputDevices);
  useEffect(() => {
    audioInputDevicesRef.current = audioInputDevices;
  }, [audioInputDevices]);

  // 規定のデバイスIDが確認できたら選択させる
  useEffect(() => {
    if(recorderDeviceId !== undefined){
      setSelectedAudioInputDevice(recorderDeviceId);
    }
  }, [recorderDeviceId]);

  // 選択デバイス変更の検知
  const handleChangeDevice = useCallback((deviceId: string) => {
    setSelectedAudioInputDevice(deviceId);
    recorderBuild(isStartedRef.current, false, deviceId);
  }, [recorderBuild]);

  // デバイス一覧の更新
  const fetchAudioInputDevices = useCallback( async () => {
    let stream;
    try {
      if(browser && browser.name === 'firefox'){
        stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false, });
      }
      const audioInput = (
        await navigator.mediaDevices.enumerateDevices()
      ).filter((d) => d.kind === "audioinput");
      setAudioInputDevices(audioInput);
      return audioInput;
    } catch (e) {
      throw e;
    } finally {
      if(stream){
        stream.getTracks().forEach(track => track.stop());
      }
    }
  }, [browser]);

  // デバイス一覧の初期化
  const refreshDevices = useCallback(async () => {
    const audioInput = await fetchAudioInputDevices();
    if(audioInput.length === 0 || (audioInput.length === 1 && audioInput[0].deviceId === "")){
      const intervalId = setInterval(async () => {
        if(audioInputDevicesRef.current.length === 0 || (audioInputDevicesRef.current.length === 1 && audioInputDevicesRef.current[0].deviceId === "")){
          await fetchAudioInputDevices();
        }else{
          clearInterval(intervalId);
        }
      }, 2000);
    }
  }, [fetchAudioInputDevices]);

  useEffect(() => {
    // 初回デバイス一覧取得
    refreshDevices();
    // デバイスの着脱が行われるタイミングで更新処理を実施
    navigator.mediaDevices.addEventListener("devicechange", refreshDevices);
    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        refreshDevices
      );
    };
  }, [refreshDevices]);

  // ボタン無効化（クリック不可）
  const disableToggle = useCallback(() => {
    // ボタン無効化（クリック不可）
    setIsDisabled(true);
  }, []);

  // ボタン有効化（クリック可）
  const enableToggle = useCallback(() => {
    setIsDisabled(false);
  }, []);

  // 録音状態を呼び出し元に伝播させる
  useEffect(() => {
    onRecordStateChange({isRecording: isStarted, isDisabled});
  }, [isDisabled, isStarted, onRecordStateChange]);

  // 言語切替時の処理
  useEffect(() => {
    const handleLanguageChange = () => {
      const currentIsStarted = isStartedRef.current;
      if(isInitializedRef.current && isStartedRef.current){
        // ドラフト文表示停止
        dismissInterim();
        if(!currentIsStarted){
          recorderStop(false);
        }else{
          recorderStop(true).then(() => {
            recorderStart()
          })
        }
      }
    };
    // ローカルストレージのデータが変更された際に発火するリスナーとして追加
    window.addEventListener('localstorage-change-language', handleLanguageChange);
    window.addEventListener('localstorage-change-translationLanguage', handleLanguageChange);

    return () => {
      // リスナー解除
      window.removeEventListener('localstorage-change-language', handleLanguageChange);
      window.removeEventListener('localstorage-change-translationLanguage', handleLanguageChange);
    };
  }, [dismissInterim, recorderStop, recorderStart]);

  // コンポーネント外部からもメソッドを使用可能にする
  useImperativeHandle(ref, () => ({
    startRecording: start,
    stopRecording: stop,
    handleToggle,
    disableToggle,
    enableToggle,
    getIsRecording: () => isStarted,
    getIsDisabled: () => isDisabled,
    updateClusterAmount,
  }));

  return isConnected ? (
    <div className='record-container my-2 pt-2'>
      <div className="row align-items-center flex-nowrap">
        <div className="col-4">

          {selectedAudioInputDevice && audioInputDevices.length !== 0 && (
            <select
              className="form-select form-select-sm"
              style={{height: "32px", top: "-10px", position: "relative", fontSize: "13px"}}
              disabled={isDisabled || !isAnalyzed || selectedAudioInputDevice.length === 0}
              value={selectedAudioInputDevice}
              onChange={ e => handleChangeDevice(e.target.value)}
            >
              {audioInputDevices.map( (device, index) => <option key={index} value={device.deviceId}>{device.label}</option>)}
            </select>
          )}
          {process.env.REACT_APP_ENVIRONMENT !== "production" && currentNoiseLevel !== undefined && (
            <div className={`${currentNoiseLevel >= 60 ? 'text-danger' : currentNoiseLevel >= 50 ? 'text-warning' : 'text-primary' }`}>ノイズ: <b>Lv.{Math.ceil(currentNoiseLevel)}</b> / (<b>{Math.ceil(((currentNoiseDuration ?? 0) / 1000))}</b>/10秒)</div>
          )}
          <SecondCounter />
        </div>
        <div className="col-4">
          <div className="d-flex justify-content-center">
            <div className="d-flex justify-content-center control-panel">
              <button
                className={`mic-btn p-2 ${isStarted ? 'active' : ''}`}
                disabled={isDisabled || !isAnalyzed}
                onClick={handleToggle}
              >
                {isStarted
                  ? <i className="bi bi-mic-fill mic-icon"></i>
                  : <i className="bi bi-mic-mute-fill mic-icon"></i>
                }
              </button>
            </div>
          </div>
        </div>
        {process.env.REACT_APP_ENVIRONMENT !== "production" && (
          <div className="col-4">
            <button
              className={`w-100 btn btn-sm ${isMonitoring ? 'btn-danger' : 'btn-primary'}`}
              style={{marginBottom: "5px"}}
              disabled={isDisabled || !isStarted}
              onClick={handleWatchToggle}
            >
              {isMonitoring
                ? '保存'
                : '録音'
              }
            </button>
            <audio
              className={`w-100`}
              style={{height: "26px", marginTop: "5px"}}
              src={dataURL}
              controls
            />
          </div>
        )}
      </div>
      <small className={`mb-2 fs-8 ${isDisabled || isStarted ? 'd-none' : ''}`}>
        {t('マイクボタンをクリックし、会話をスタートしてください。')}
      </small>
    </div>
  ) : (
    <div className='record-container mb-1 mt-2 py-2 text-danger'>
      <p className="m-0 mb-1 fw-bold">
        {t('マイクが取得できませんでした。')}
      </p>
      <div>
        <p className="m-0">
          {t('ブラウザをリロードして「マイクの許可」を行うか、他のタブやアプリがマイクを使用していないか確認してください。')}
        </p>
      </div>
    </div>
  )
});

export default observer(RecordButton)
