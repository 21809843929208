import React, { createContext, useContext, useRef } from 'react';
import InterimToast, { InterimToastHandles } from '../component/InterimToast';

const ToastContext = createContext<InterimToastHandles | null>(null);

export const ToastProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const toastRef = useRef<InterimToastHandles>(null);

  const showInterim = (message: string) => {
    toastRef.current?.showInterim(message);
  };

  const dismissInterim = () => {
    toastRef.current?.dismissInterim();
  };

  const setIsEnabled = (isEnabled: boolean) => {
    toastRef.current?.setIsEnabled(isEnabled);
  };

  return (
    <ToastContext.Provider value={{ showInterim, dismissInterim, setIsEnabled }}>
      {children}
      <InterimToast ref={toastRef} />
    </ToastContext.Provider>
  );
};

// Custom hook to use toast
export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
