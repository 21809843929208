import { doc, setDoc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { UserLanguage } from '../types/userLanguage'
import { LocalStorage } from '../store/LocalStorage'
import i18next from 'i18next';

// firestore に保存するユーザー言語設定データを追加・更新する
export const updateUserLanguage = async (roomOwnerId: string, meetingId: string) => {
  const meetingDocRef = doc(firestore, `users/${roomOwnerId}/meetings/${meetingId}`);
  const clientId = LocalStorage.uid as string;
  const language = i18next.language;
  const languages = LocalStorage.language;
  const translationLanguage = LocalStorage.translationLanguage;

  try {
    const docSnapshot = await getDoc(meetingDocRef);
    if (!docSnapshot.exists()) {
      // firestore にまだユーザー言語設定データがない場合は追加
      // ※会議作成時はまだユーザー言語設定データがない
      const newUserLanguage: UserLanguage = {
        id: clientId,
        language: language,
        languages: languages,
        translationLanguage: translationLanguage,
      };
      await setDoc(meetingDocRef, {
        userLanguages: [newUserLanguage]
      });
      // console.log(`Meeting created and user language data added successfully.`, newUserLanguage);
    } else {
      // firestore にユーザー言語設定データが存在する場合は更新
      const existingUserLanguages: UserLanguage[] = docSnapshot.data().userLanguages || [];
      const userLanguageIndex = existingUserLanguages.findIndex(userLanguage => userLanguage.id === clientId);
      if (userLanguageIndex !== -1) {
        // firestore にすでに同じユーザーの言語設定データが存在する場合は更新
        existingUserLanguages[userLanguageIndex].language = language;
        existingUserLanguages[userLanguageIndex].languages = languages;
        existingUserLanguages[userLanguageIndex].translationLanguage = translationLanguage;
        await updateDoc(meetingDocRef, {
          userLanguages: existingUserLanguages
        });
        // console.log(`change language : ${language} (id: ${clientId})`, existingUserLanguages[userLanguageIndex]);
      } else {
        // firestore に同じユーザーの言語設定データが存在しない場合は追加
        const newUserLanguage: UserLanguage = {
          id: clientId,
          language: language,
          languages: languages,
          translationLanguage: translationLanguage,
        };
        await updateDoc(meetingDocRef, {
          userLanguages: [...existingUserLanguages, newUserLanguage]
        });
        // console.log(`language : ${language} (id: ${clientId}) added successfully.`, newUserLanguage);
      }
    }
  } catch (error) {
    console.error("Error adding user language:", error);
  }
};
