import { useCallback, useEffect, useRef, useState } from 'react'
import { io, Socket } from 'socket.io-client';
import { getLanguageCodes } from '../util/language';
import { UseSpeechArgs, UseSpeechHandles } from '../types/speech';
import { useNoiseMonitor } from './useNoiseMonitor';
import toast from 'react-hot-toast';
import { sentryLog } from '../util/sentry';
import { useTranslation } from 'react-i18next';
import { LocalStorage } from '../store/LocalStorage';

// 再接続の設定
const RECONNECTION_ATTEMPTS = 5;
const RECONNECTION_DELAY = 1000;
const RECONNECTION_DELAY_MAX = 5000;

// リトライの設定
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

// バッファサイズ
const BUFFER_SIZE = 3;

/**
 * マイクからの音声の録音と解析を行うためのReactフックの表現。
 *
 * @param {Object} UseSpeechArgs - フック設定のためのパラメータ。
 * @param {string} UseSpeechArgs.userId - ユーザーID。
 * @param {string} UseSpeechArgs.roomOwnerId - ルームオーナーのID。
 * @param {string} UseSpeechArgs.meetingId - ルームのID。
 * @param {boolean} UseSpeechArgs.needInterim - 中間結果が必要かどうか。
 * @param {function} UseSpeechArgs.onMessage - メッセージ処理用の関数。
 * @param {function} UseSpeechArgs.onDataAvailable - 録音データが利用可能になった際の処理を行う関数。
 * @param {function} UseSpeechArgs.onError - エラー発生時の処理を行う関数。
 *
 * @returns {Object} UseSpeechHandles - 録音プロセス用のコールバック関数と状態変数を含むオブジェクト。
 * @returns {boolean} UseSpeechHandles.initialized - 初期化が完了し、接続が確立しているかどうかを示すブール値。
 * @returns {Object} UseSpeechHandles.initializedRef - 接続が確立しているかどうかを参照するrefオブジェクト。
 * @returns {function} UseSpeechHandles.start - 音声文字変換処理を開始するための関数。
 * @returns {function} UseSpeechHandles.stop - 音声文字変換処理を停止するための関数。
 * @returns {function} UseSpeechHandles.setUseStart - このhookを使用するかどうかを設定するための関数。
 */
export const useAzureSpeech = ({
 userId,
 roomOwnerId,
 meetingId,
 needInterim,
 onMessage,
 onDataAvailable,
 onError,
}: UseSpeechArgs): UseSpeechHandles => {
  const { t } = useTranslation();

  // リトライ可能な操作のラッパー
  const withRetry = useCallback(async <T,>(
    operation: () => Promise<T>,
    errorMessage: string
  ): Promise<T> => {
    let lastError: Error | null = null;

    for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
      try {
        return await operation();
      } catch (error) {
        lastError = error as Error;
        console.error(`Attempt ${attempt + 1}/${MAX_RETRIES} failed:`, error);

        if (attempt < MAX_RETRIES - 1) {
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, attempt)));
        }
      }
    }

    const finalError = `${errorMessage}: ${lastError?.message || 'Unknown error'}`;
    handlersRef.current.onError(finalError);
    throw new Error(finalError);
  }, []);

  // hookのイベントハンドラ
  const handlersRef = useRef({
    onMessage,
    onError
  });
  useEffect(() => {
    handlersRef.current = {onMessage, onError};
  }, [onMessage, onError]);

  // 起動するかどうか
  const [useStart, setUseStart] = useState<boolean>(false);

  // ソケット通信
  const [socket, setSocket] = useState<Socket | null>(null);
  const socketRef = useRef(socket);
  useEffect(() => {
    socketRef.current = socket;
  }, [socket]);

  // ソケット通信開通したかどうか
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const isSocketConnectedRef = useRef(isSocketConnected);
  useEffect(() => {
    isSocketConnectedRef.current = isSocketConnected;
  }, [isSocketConnected]);

  // レコーダー
  const audioContextRef = useRef<AudioContext>();
  const mediaStreamAudioSourceNodeRef = useRef<MediaStreamAudioSourceNode>();
  const audioWorkletNodeRef = useRef<AudioWorkletNode>();
  const isRecordingRef = useRef<boolean>(false);
  const streamRef = useRef<MediaStream>();
  const languagesRef = useRef<string[]>([]);
  const audioBufferQueueRef = useRef<Array<{data: ArrayBuffer, timestamp: number}>>([]);

  // ノイズレベル確認
  const showedAlertRef = useRef<boolean>(false);
  const initializedWorkerRef = useRef<boolean>(false);
  const {
    currentNoiseLevel,
    currentNoiseDuration,
    analyzeAudio,
    initializeWorker,
    setIsTranscribing
  } = useNoiseMonitor({
    onNoiseAlert: () => {
      if (!showedAlertRef.current) {
        showedAlertRef.current = true;
        const id = toast(<div onClick={() => toast.dismiss(id)} style={{ cursor: 'pointer' }}>{t('大きな環境音を検出しました。')}<br/>{t('ノイズの多い環境では、音声の文字起こしが困難な場合があります。')}<br/>{t('できるだけ静かな環境で使用してください。')}</div>, {
          duration: 10000,
          position: 'top-right',
          icon: '⚠️',
        });
      }
    }
  });

  const recognizing = useCallback( async (text: string, language: string) => {
    setIsTranscribing(true);
    handlersRef.current.onMessage(text, false, language);
  }, [setIsTranscribing]);

  const recognized = useCallback( async (text: string, language: string, speakerId: string) => {
    setIsTranscribing(false);
    handlersRef.current.onMessage(text, true, language, undefined, speakerId);
  }, [setIsTranscribing]);

  const recorderCleanup = useCallback(async (streamClear: boolean) => {
    // AudioWorkletNodeのクリーンアップ
    if (audioWorkletNodeRef.current) {
      audioWorkletNodeRef.current.port.onmessage = null;
      audioWorkletNodeRef.current.disconnect();
      audioWorkletNodeRef.current = undefined;
    }

    // MediaStreamSourceNodeのクリーンアップ
    if (mediaStreamAudioSourceNodeRef.current) {
      mediaStreamAudioSourceNodeRef.current.disconnect();
      if (streamClear) {
        mediaStreamAudioSourceNodeRef.current.mediaStream.getTracks().forEach(track => track.stop());
      }
      mediaStreamAudioSourceNodeRef.current = undefined;
    }

    // AudioContextのクリーンアップ
    if (audioContextRef.current) {
      await audioContextRef.current.close();
      audioContextRef.current = undefined;
    }

    // バッファのクリーンアップ
    audioBufferQueueRef.current = [];
    initializedWorkerRef.current = false;
  }, []);

  const recorderSetUp = useCallback(async (stream: MediaStream, streamClear: boolean) => {
    // 既存のリソースをクリーンアップ
    await recorderCleanup(streamClear);

    // AudioContextの初期化と管理
    const initializeAudioContext = async () => {
      audioContextRef.current = new AudioContext();
      if (audioContextRef.current.state === 'suspended') {
        await audioContextRef.current.resume();
      }
    };

    // AudioWorkletの初期化
    const initializeAudioWorklet = async () => {
      if (!audioContextRef.current) throw new Error('AudioContext not initialized');

      await audioContextRef.current.audioWorklet.addModule('/worklet/recorder-processor.js');
      console.log('AudioWorklet initialized successfully');
    };

    // AudioNodeの設定
    const setupAudioNodes = async () => {
      if (!audioContextRef.current) throw new Error('AudioContext not initialized');

      // MediaStreamSourceNodeの設定
      mediaStreamAudioSourceNodeRef.current = audioContextRef.current.createMediaStreamSource(stream);

      // AudioWorkletNodeの設定
      audioWorkletNodeRef.current = new AudioWorkletNode(audioContextRef.current, 'recorder-processor', {
        processorOptions: {
          sampleRate: audioContextRef.current.sampleRate,
          bufferSize: 4096,
        },
        numberOfInputs: 1,
        numberOfOutputs: 1,
        channelCount: 1,
        channelCountMode: 'explicit',
        channelInterpretation: 'speakers'
      });

      // ノードの接続
      mediaStreamAudioSourceNodeRef.current.connect(audioWorkletNodeRef.current);

      // メッセージハンドラの設定
      audioWorkletNodeRef.current.port.onmessage = async (event) => {
        if (!socketRef.current || !isRecordingRef.current) return;

        const arrayBuffer = event.data;
        const blob = new Blob([arrayBuffer], { type: 'audio/wav' });

        try {
          const rawPCM = arrayBuffer.slice(44);
          audioBufferQueueRef.current.push({
            data: rawPCM,
            timestamp: Date.now()
          });

          if (audioBufferQueueRef.current.length >= BUFFER_SIZE) {
            const chunk = audioBufferQueueRef.current.shift();
            if (chunk) {
              socketRef.current.emit('audioData', {
                ownerId: roomOwnerId,
                roomId: meetingId,
                audioData: chunk.data,
                timestamp: chunk.timestamp
              });
            }
          }

          if (!initializedWorkerRef.current) {
            initializeWorker(audioContextRef.current?.sampleRate ?? 44100);
            initializedWorkerRef.current = true;
          }
          analyzeAudio(rawPCM);

          if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
            await onDataAvailable(blob);
          }
        } catch (e: any) {
          console.error(e);
          sentryLog(e);
        }
      };
    };

    try {
      // AudioContextの初期化
      await withRetry(
        initializeAudioContext,
        'Failed to initialize AudioContext'
      );

      // AudioWorkletの初期化
      await withRetry(
        initializeAudioWorklet,
        'Failed to initialize AudioWorklet'
      );

      // AudioNodeの設定
      await withRetry(
        setupAudioNodes,
        'Failed to setup audio nodes'
      );

    } catch (error) {
      // エラー発生時のクリーンアップ
      await recorderCleanup(streamClear);
      throw error;
    }
  }, [analyzeAudio, initializeWorker, meetingId, onDataAvailable, recorderCleanup, roomOwnerId, withRetry]);

  const recorderStop = useCallback(() => {
    isRecordingRef.current = false;
    socketRef.current && socketRef.current.emit('stopSpeechRecognition', {
      ownerId: roomOwnerId,
      roomId: meetingId
    });
  }, [meetingId, roomOwnerId]);

  const recorderStart = useCallback(async (stream: MediaStream) => {
    await withRetry(async () => {
      isRecordingRef.current = true;

      let sessionClear = false;
      let streamClear = false;
      // 言語が前回と異なる場合
      if (languagesRef.current.join(',') !== getLanguageCodes().join(',')) {
        languagesRef.current = getLanguageCodes();
        sessionClear = true;
      } else if (streamRef.current && streamRef.current.getAudioTracks()[0].getSettings().deviceId !== stream.getAudioTracks()[0].getSettings().deviceId) {
        streamRef.current.getTracks().forEach(track => track.stop());
        sessionClear = true;
        streamClear = true;
      }
      streamRef.current = stream;
      await recorderSetUp(stream, streamClear);

      if (sessionClear && socketRef.current) {
        socketRef.current && socketRef.current.emit('startSpeechRecognition', {
          languages: languagesRef.current,
          needInterim,
          ownerId: roomOwnerId,
          roomId: meetingId
        });
      }
    }, 'Failed to start recording');
  }, [meetingId, needInterim, recorderSetUp, roomOwnerId, withRetry]);

  const start = useCallback( async (stream: MediaStream) => {
    console.log('startAzureCloudStream ==')
    await recorderStart(stream);
  },[recorderStart]);

  const stop = useCallback(() => {
    console.log('stopAzureCloudStream')
    recorderStop();
  }, [recorderStop]);

  // 再接続試行回数の追跡
  const reconnectionAttemptsRef = useRef(0);
  // リトライ処理中かどうか
  const isRetryingRef = useRef(false);

  // 再接続のための指数バックオフ
  const getReconnectionDelay = useCallback(() => {
    const attempt = reconnectionAttemptsRef.current;
    return Math.min(RECONNECTION_DELAY * Math.pow(2, attempt), RECONNECTION_DELAY_MAX);
  }, []);

  // 再接続処理
  const handleReconnection = useCallback(async () => {
    if (isRetryingRef.current || !useStart) return;

    isRetryingRef.current = true;
    try {
      if (reconnectionAttemptsRef.current < RECONNECTION_ATTEMPTS) {
        reconnectionAttemptsRef.current++;
        const delay = getReconnectionDelay();

        console.log(`Attempting reconnection ${reconnectionAttemptsRef.current}/${RECONNECTION_ATTEMPTS} after ${delay}ms`);
        await new Promise(resolve => setTimeout(resolve, delay));

        // 現在のストリームで再初期化
        if (streamRef.current) {
          await recorderStart(streamRef.current);
        }
      } else {
        console.error('Max reconnection attempts reached');
        handlersRef.current.onError('Unable to establish connection after multiple attempts');
        setUseStart(false);
      }
    } finally {
      isRetryingRef.current = false;
    }
  }, [getReconnectionDelay, recorderStart, useStart]);

  useEffect(() => {
    if(useStart){
      const baseUrl = process.env.REACT_APP_BASE_URL;
      if (!baseUrl) {
        throw new Error("REACT_APP_BASE_URL is not defined");
      }
      const newSocket = io(baseUrl.replace('/api', ''), {
        path: '/azure_speech',
        rejectUnauthorized: true,
        secure: true,
        transports: ['websocket'],
        reconnection: true,
        reconnectionAttempts: RECONNECTION_ATTEMPTS,
        reconnectionDelay: RECONNECTION_DELAY,
        reconnectionDelayMax: RECONNECTION_DELAY_MAX,
        timeout: 20000,
        auth: {
          userId: LocalStorage.uid,
        },
      });
      setSocket(newSocket);
      return () => {
        reconnectionAttemptsRef.current = 0;
        if (newSocket) {
          newSocket.disconnect();
        }
      };
    }
  }, [useStart, userId]);

  useEffect(() => {
    if(socket){
      //console.log('socket create')
      socket.off();
      socket.on('connect', () => {
        console.log('Azure Speech Socket connected');
        setIsSocketConnected(true);
        reconnectionAttemptsRef.current = 0;
      })

      socket.on('connect_error', async (error) => {
        console.error('Azure Speech Connection error:', error);
        await handleReconnection();
      });

      socket.on('disconnect', (reason) => {
        console.log('Azure Speech Socket disconnected:', reason);
        setIsSocketConnected(false);

        if (reason === 'io server disconnect') {
          // サーバーが切断を開始した場合は手動で再接続
          socket.connect();
        }
        // その他の切断理由の場合は自動再接続が行われる
      })

      //console.log('socket finalResult event set')
      socket.on('finalResult', async ({text, language, speakerId}: {text: string, language: string, speakerId: string}) => {
        //console.log('socket finalResult event fired')
        await recognized(text, language, speakerId);
      })
      if (needInterim) {
        //console.log('socket interimResult event set')
        socket.on('interimResult', async ({text, language}: {text: string, language: string}) => {
          //console.log('socket interimResult event fired')
          await recognizing(text, language);
        })
      }
      socket.on('application_error', (msg: string) => {
        onError(msg);
      })
      socket.on('error', async (error) => {
        console.error('Azure Speech Socket error:', error);
        await handleReconnection();
      });
    }
    return () => {
      if(socket){
        //console.log('socket cleanup')
        socket.off();
      }
    }
  }, [handleReconnection, meetingId, needInterim, onError, recognized, recognizing, roomOwnerId, socket]);

  return {
    initialized: isSocketConnected,
    initializedRef: isSocketConnectedRef,
    start,
    stop,
    setUseStart,
    currentNoiseLevel,
    currentNoiseDuration,
  }
}
