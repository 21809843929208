import { Dispatch, MutableRefObject, SetStateAction } from 'react';

export interface RecordButtonProps {
  meetingId: string;
  onData?: (talkId: string, speakerId: string, text: string, language: string, isFinal: boolean) => void;
  onRecordStateChange: ({isRecording, isDisabled}: {isRecording: boolean, isDisabled: boolean}) => void;
  changeLabels: (idMap: { [id: string]: string }) => void;
  roomOwnerId: string;
}

export interface RecordButtonHandles {
  startRecording: (disabled?: boolean) => void;
  stopRecording: (disabled?: boolean) => void;
  handleToggle: () => void;
  disableToggle: () => void;
  enableToggle: () => void;
  getIsRecording: () => boolean;
  getIsDisabled: () => boolean;
  updateClusterAmount: (value: any) => void;
}

export enum useSttType {
  Custom  ,
  Google,
  Azure,
}

export interface useWavRecordProps {
  roomOwnerId: string;
  meetingId: string;
  clusterAmount: MutableRefObject<number>;
  onSetDisabled: (disabled: boolean) => void;
  onMessage: (message: string, isFinal?: boolean, language?: string, talkId?: string, speakerId?: string) => void;
}

export interface useWavRecordHandles {
  isConnected: boolean;
  isAnalyzed: boolean;
  isStarted: boolean;
  isMonitoring: boolean;
  isErrored: boolean;
  build: (start: boolean, disabled?: boolean, orderDeviceId?: string) => Promise<boolean>,
  start: () => Promise<boolean>,
  stop: (disabled?: boolean) => Promise<boolean>,
  monitoringStart: () => void,
  monitoringStop: () => void,
  dataURL: string,
  deviceId: string | undefined;
  errorMessage: MutableRefObject<string>;
  errorReport: MutableRefObject<string>;
  currentNoiseLevel?: number;
  currentNoiseDuration?: number;
}

export type UseSpeechArgs = {
  userId: string;
  roomOwnerId: string;
  meetingId: string;
  needInterim: boolean;
  clusterAmount?: MutableRefObject<number>;
  onMessage: (message: string, isFinal?: boolean, language?: string, talkId?: string, speakerId?: string) => void;
  onDataAvailable: (blob: Blob) => Promise<void>;
  onError: (message: string) => void;
}

export interface UseSpeechHandles {
  initialized: boolean;
  initializedRef: MutableRefObject<boolean>;
  start: (stream: MediaStream) => Promise<void>;
  stop: () => void;
  setUseStart: Dispatch<SetStateAction<boolean>>;
  currentNoiseLevel?: number;
  currentNoiseDuration?: number;
}

export const SpeakerSeparateMode = {
  // Perform Clustering Recognition.
  Clustering: "clustering",

  // Perform Cosine Similarity Check.
  CosineSimilarity: "cosine_similarity",

  // Perform no diarization and return everything as one user.
  NoDiarization: "no_diarization",
} as const;

export type TSpeakerSeparateMode = typeof SpeakerSeparateMode[keyof typeof SpeakerSeparateMode];

export interface ParamOptions {
  connectionId: string,
  ioVersion:    "v0.1",

  // A list of language to consider for identification.
  // language must be specified in ISO639 form.
  // defualt: [] (automatically detects language)
  // example: ["ja", "en"]
  languages: string[],

  // A tolerance value for speaker separation.
  // lower the value more strict they get.
  // value range: 0.0 ~ 1.0 (Most Strict ~ Most Lenient)
  // default:     0.6
  allowableDistance: number,

  // A volume threshold of which the server should consider as silence.
  // described in DB unit, must not go over 0.0.
  // default: -40.0
  silenceThreshold: number,

  // Initial Prompt for Whisper to consider.
  // for further details, refer to whisper's documentation about initial prompt.
  initialPrompt: string,

  // Amount of speakers that are definitely present in this conversation.
  // Only set when you're aware.
  // default: 2
  numOfSpeakers: number,

  // denotes the mode for recognizer to work.
  // default: "clustering"
  speakerSeparateMode: TSpeakerSeparateMode,

  // whether you perform the draft transcription.
  // default: true
  doDraftTranscribe: boolean,
}

// Init event that needs to be sent to server upon connection
// to properly start a recognition event.
export interface InitParam {
  signal: "init_params",
  params: Partial<ParamOptions>,
}

// Option update parameter that allows you to overwrite
// previously set options on InitParam.
// Options set by InitParam or SetParam will persist for the
// rest of this communication life cycle unless overwritten.
export interface SetParam {
  signal: "set_params",
  params: Partial<ParamOptions>,
}

// Voice Chunk sent to server for classification.
// this "options" value will overwrite the option set by SetParam or InitParam
// ONLY FOR THIS recognition.
// it does not affect subsequent recognition request.
export interface VoiceChunk {
  signal: "voice_chunk",
  data:   string,

  // This settings overwrites settings ONLY for this
  // voice chunk.
  options: Partial<ParamOptions>,
}


// Usable for flushing a transcription buffer.
export interface DoTranscribe {
  signal: "do_transcribe",
  options: Partial<ParamOptions>,
}

// Response

export interface ResponseChunk {
  // Segment label for classifying which sentence this chunk belongs to.
  talkLabel:    string,
  // Speaker label. self explanatory.
  speakerLabel: string,
  // detected STT-ed words. self explanatory.
  text:         string,
}

export interface DraftResponse {
  signal: "drafTtext", // Typo?
  // Usually 200.
  statusCode: number,
  // mostly unused, usually empty.
  message: string,
  data: {
    // Detected language.
    language: string,
    // confidence of detected language.
    // range: 0.0 ~ 1.0
    languageProbability: number,
    results: ResponseChunk[],
  }
}

export interface NewData {
  signal: "newData",
  // Usually 200.
  statusCode: number,
  // mostly unused, usually empty.
  message: string,
  data: {
    // Detected language.
    language: string,
    // confidence of detected language.
    // range: 0.0 ~ 1.0
    languageProbability: number,
    results: ResponseChunk[],
  }
}

export interface Labels {
  signal: "labels",
  // Usually 200.
  statusCode: number,
  // mostly unused, usually empty.
  message: string,

  data: {
    // This is used for renewing a tie of
    // talk label -> Speaker Label.
    labels: {
      [key: string]: string
    }
  }
}

// Some Server message.
export interface ServerMessage {
  signal: "message",
  // Usually 200. not significant.
  statusCode: number,
  message: string,
}

// Server error message.
export interface ErrorMessage {
  signal: "error",
  statusCode: number,
  // Error message.
  message: string,
}

// Ping message sent from the server to indicate that
// the connection is indeed alive.
export interface Heartbeat {
  signal: "heartbeat",
  // Usually 200.
  statusCode: number,
  // Usually empty.
  message: string,
}

export type ServerEvent = DraftResponse
  | NewData
  | ServerMessage
  | Labels
  | ErrorMessage
  | Heartbeat

